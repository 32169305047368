<template>
  <section >
    <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
    <h2 style="margin-top: 25px;
              margin-bottom: 25px;
              color: #9f9f9f;
              font-size: 31px;
              font-weight: 400;">

      <i class="fas fa-hotel" style="margin-right:10px"></i>
      {{escola.nome}}
    </h2>


    <div class="col-md-12" style="padding:20px;background-color:#f8f9fa;margin-bottom:20px;" v-if="etapasALL.length != 0">
      <pm-TabView>
        <pm-TabPanel v-for="t in etapasALL" :key="t.nome" :header="t.nome">
          <div v-for="s in t.series" :key="s" style="margin-bottom:30px;">
              <b>{{s.nome}}</b>
              <div class="row" style="margin-top: 15px;margin-bottom:15px">
                <div class="col-md-2" v-for="tu in s.turmas" :key="tu" @click="enviarTurmaNew(tu.segmento_id, tu.serie_id, tu.id, tu.turno)" style="cursor:pointer" >
                  <va-card>
                    <div v-if="tu.qdt_alunos>0" style="color: rgb(255, 255, 255);border-color: rgb(61, 146, 9);
                      background-color: rgb(61, 146, 9);opacity: 1;
                      width: 33px;
                      padding: 3px;
                      text-align: center;
                      float: right;
                      border-radius: 15px;
                      font-weight: 800;
                      margin-bottom:20px;"
                    >
                      {{tu.qdt_alunos}}
                    </div>
                    <div v-else></div>

                    <div style="padding-top:20px" class="row">
                      <div class="flex md12" style="padding:0px;padding-bottom:10px;">
                        <i class="fas fa-book-reader" style="font-size: 48px;text-align: center;width: 100%;color: #4387dd;"  ></i>
                      </div>
                      <div class="flex md12" style="text-align: center; padding:0px;color: #4387dd;">
                        <p>{{tu.nome}}</p>
                        <p style="margin-bottom: 22px;">{{tu.turno}}</p>
                      </div>
                    </div>
                  </va-card>
                </div>
              </div>
          </div>
        </pm-TabPanel>
      </pm-TabView>
    </div>
    <div v-else class="col-md-12" style="padding:20px;background-color:#f8f9fa;margin-bottom:20px;">
      <div class="alert alert-danger" >
          <strong>Nenhuma Turma criada!</strong>
      </div>
    </div>

    <div class="field col-12 md:col-2">
        <pm-Button label="Voltar" class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="this.$router.push({name: 'escola-coordenacao'});" />
    </div>


  </section >
</template>

<script>

import alertaAnoLetivo from "@/components/alerta_anoletivo.vue";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";
import { Calendario } from "@/class/calendario.js";


export default {
  props: {
    idEscola:{
    },
  },
  components: {
    alertaAnoLetivo
  },
  name: 'form-elements',
  data () {
    return {
      situacaoAno:1,
      escolaHab:0,
      ano: [],
      etapasALL: [],
      periodosALL: [],
      turmasALL: [],
      selectedEtapas:[],
      selectedPeriodos:[],
      escola: {
        id:null,
        cnpj:null,
        nome:null,
        codigo_INEP:null,
        email:null,
        cep:'',
        email:null,
        numero: null,
        complemento: null,
        bairro:null,
        cidade:null,
        estado:null,
        logradouro: "",
        telefone:null,
        logradouro: null,
        user_id: store_token_info.usuario_logado,
      },
      cepValido: 1,
      isLoading: false,
      errorMessages: ['Digite um CEP válido!'],
      showTurmaModal:false,
      st_idEscola:null,
      erro:0,
    }
  },
  methods: {
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.ano = data.data.ano;
    },

    async listaTudo(){
      let anoSelect = sessionStorage.getItem("anoSelecionado");
      let data = await Escola.obtemInfoEscola(this.st_idEscola,anoSelect);
      this.etapasALL = data.data;
    },

    async buscarPeriodosDaEtapa(etapa_id) {
      this.periodosALL = [];
      let data = await SeriesEscolar.buscaPorSegmento(etapa_id);
      this.periodosALL = data.data;
    },
    async buscarTurmasDoPeriodo(etapa_id, periodo_id,segmento,serie) {
      this.turmasALL = [];
      let novo = {
        ano: this.ano,
        escola_id: this.st_idEscola,
        segmento_id: etapa_id,
        serie_id: periodo_id,
      }
      let data = await Turma.turmasAvancada(novo);

      for (const el of data.data) {
        let novo ={
          id: el.id,
          ano: el.ano,
          escola_id: el.escola_id,
          segmento_id: el.segmento_id,
          serie_id: el.serie_id,
          nome: el.nome,
          turno: el.turno,
          ativa: el.ativa,
          situacao: el.situacao,
          segmento:segmento,
          serie:serie,
        }
        this.turmasALL.push(novo);
      }

      this.showTurmaModal = true;
    },
    async listarPeriodos() {
      this.periodosALL = [];
      for (var i = 0; i < this.selectedEtapas.length; i++) {
        let novo = {
          etapaId: this.selectedEtapas[i].id,
          etapaNome : this.selectedEtapas[i].nome,
          periodosDaEtapa : [],
        }
        let data = await SeriesEscolar.buscaPorSegmento(this.selectedEtapas[i].id);
        for (var j = 0; j < data.data.length; j++) {
          data.data[j].etapaNome = this.selectedEtapas[i].nome;
          novo.periodosDaEtapa.push(data.data[j]);
        }
        this.periodosALL.push(novo);
      }
    },
    async listarTurmas(periodo) {
      this.turmasALL = []
      for (var i = 0; i < this.selectedPeriodos.length; i++) {
        let novo1 = {
          ano: this.ano,
          escola_id: this.st_idEscola,
          segmento_id: this.selectedPeriodos[i].segmento_id,
          serie_id: this.selectedPeriodos[i].id
        }
        let data = await Turma.turmasAvancada(novo1);
        let novo2 = {
            etapaNome: this.selectedPeriodos[i].etapaNome,
            serieNome: this.selectedPeriodos[i].nome,
            turmasPorPeriodo: data.data,
        }
       this.turmasALL.push(novo2);
      }
    },
    async clear () {
      this.periodosALL = [];
    },
    async buscarCep() {
      if (this.escola.cep.length == 8) {
         let data = await buscarCEP.searchCep(this.escola.cep);
        if (data['erro']) {
          this.cepValido = 0;
        }
        else {
          this.escola.logradouro = data['logradouro'],
          this.escola.estado = data['uf'],
          this.escola.cidade = data['localidade'],
          this.escola.bairro = data['bairro'],
          this.cepValido = 1;
        }
      }
    },
    enviarTurmaNew(etapa_id, periodo_id, turma_id, turno) {
      sessionStorage.setItem("TurmaConfg_etapa_id", etapa_id);
      sessionStorage.setItem("TurmaConfg_periodo_id", periodo_id);
      sessionStorage.setItem("TurmaConfg_turma_id", turma_id);
      sessionStorage.setItem("TurmaConfg_escola_id", this.st_idEscola);
       sessionStorage.setItem("TurmaConfg_turno", turno);
       sessionStorage.setItem("erroturmaeditar",0);

      this.$router.push({ name: "coordenacao-turma-config", params: { etapa_id: etapa_id, periodo_id: periodo_id, turma_id: turma_id, escola_id: this.st_idEscola, turno:turno} });
    },
    enviarTurma(turmaInfo) {
      this.$router.push({ name: "coordenacao-turma-config", params: { etapa_id: turmaInfo.segmento_id, periodo_id: turmaInfo.serie_id, turma_id: turmaInfo.id, escola_id: this.st_idEscola} });
    },
    async editarOption(id){
        try {
          let data = await Escola.obtemUm(id);
          this.escola.id = id;
          this.escola.cnpj = data.data.cnpj;
          this.escola.nome = data.data.nome;
          this.escola.cep = data.data.cep;
          this.escola.email = data.data.email;
          this.escola.codigo_INEP = data.data.codigo_INEP;
          this.escola.telefone = data.data.telefone;
          this.escola.numero = data.data.numero;
          this.escola.complemento = data.data.complemento;
          this.escola.bairro = data.data.bairro;
          this.escola.cidade = data.data.cidade;
          this.escola.estado = data.data.estado;
          this.escola.logradouro = data.data.logradouro;
        } catch (e) {
          // console.log(e);
        }
    },
    async editar() {
      let data;
      try {
        data = await Escola.alterar(this.escola);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color: 'success',
          duration: 2500,
          fullWidth: false,
        });
        await this.listaTudo();
      }
      catch(e) {
        this.erro = 1;
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }

      }

    },
    habilitaredicao(){
      this.escolaHab = 1;
    },
  },
  async beforeMount() {
    if (this.idEscola != null) {
      this.st_idEscola = this.idEscola;
    }else{
      this.st_idEscola = sessionStorage.getItem("TurmaConfg_escola_id");
    }

    this.editarOption(this.st_idEscola);
    this.buscarCalendarioAtual();
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.listaTudo();

  }
}
</script>

<style scoped>
  ul {
    list-style-type: none;
  }
</style>
<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }


  .colegio-info .va-input_solid .va-input-wrapper__content .va-input__container{
    background-color: #ebebeb;
  }

  .colegio-info .va-input_solid .va-input-wrapper__content .va-input__container .va-input__content-wrapper .va-input__content .va-input__content__input{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
    opacity: 1;
    font-size: 18px;
  }

  .bdg-escola span{
    font-size: 13px;
    padding: 2px;
    border-radius: 70px;
  }
</style>
